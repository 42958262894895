.comment-box {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 0.625rem;
  box-shadow: 0.3125rem 0.3125rem 0.3125rem hsla(0, 0%, 0%, 0.1);
  padding: 1.25rem;
  margin: 0.625rem;
  display: inline-block;
  width: 100%;
  max-width: 53.125rem;
  height: auto;
  box-sizing: border-box;
}
.profile-pic {
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f4f8;
  padding: 0.5rem;
}
