@import "App/Stylesheets/core";
.ss-survey-dropdown {
  width: 13.5rem;

  .dropdown-toggle,
  .dropdown-menu {
    width: inherit;
  }

  .dropdown-toggle {
    border-radius: 0.375rem;
  }

  &.show {
    .dropdown-toggle {
      border-radius: 0.375rem 0.375rem 0 0;
    }
  }

  .dropdown-menu {
    border-radius: 0 0 0.375rem 0.375rem;
  }
}

.ss-survey-dropdown,
.ss-filter-dropdown {
  .dropdown-item {
    &:first-child {
      border-radius: 0.375rem 0.375rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 0.375rem 0.375rem;
    }
  }

  .filter-btn {
    background-color: $secondary;
    color: $white;
  }
  .dropdown-item,
  .filter-btn {
    &:active,
    &:hover,
    &:focus {
      background-color: $linen;
      color: $body-color;
    }
  }
}

.ss-filter-dropdown {
  .dropdown-item {
    &:not(:first-child) {
      border-top: 1px solid $border-color;
    }
  }

  .dropdown-menu {
    min-width: 20rem;
    inset: unset !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
}

.filter-multi-select {
  .filter-select__control {
    border: 1px solid $secondary;
  }
  .filter-select__control--is-focused {
    box-shadow: none;
  }

  .filter-select__multi-value {
    background-color: rgba($secondary, 0.25);
  }
  .filter-select__option--is-focused {
    background-color: rgba($secondary, 0.25);
  }
}
.reports-datepicker-wrap {
  .reports-datepicker {
    border: 1px solid $secondary;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    width: 15rem;
    max-width: 15rem;
  }

  .react-datepicker__close-icon {
    &:after {
      background-color: $secondary;
    }
  }
}
