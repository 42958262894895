// Flex
.flex-1 {
  flex: 1 !important;
}

// Font Size
.fs-md {
  font-size: $font-size-md !important;
}

.fs-sm {
  font-size: $font-size-sm !important;
}

.fs-xs {
  font-size: $font-size-xs !important;
}

.fs-mini {
  font-size: $font-size-mini !important;
}

.fs-large {
  font-size: $font-size-large !important;
}

// Font Weights
.fw-medium {
  font-weight: $font-weight-medium !important;
}

// Scroll
.scroll-x {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  @include scrollbars();
}

.scroll-y {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  @include scrollbars();
}

// Disabled
.disabled {
  pointer-events: none !important;
  opacity: 0.65 !important;
}

//seprator line
.ss-page-seprator {
  background-color: $gray92;
  opacity: 1;
  height: 0.125rem !important;

  &.-white {
    background-color: $white;
  }
}

//Select Control
.ss-select-control {
  width: 13rem !important;
}

//Search Control
.ss-search-control {
  width: 22rem !important;
}

//status
.bg-primary {
  &.opaque {
    @include bg-opaque($primary, 0.14);
  }
}
.bg-secondary {
  &.opaque {
    @include bg-opaque($secondary, 0.14);
  }
}
.bg-dark {
  &.opaque {
    @include bg-opaque($dark, 0.14);
  }
}

.border-pill {
  border-radius: 2rem;

  &.pill-primary {
    border: 1px solid $steel-blue;
    color: $steel-blue;
  }
}

//Progress Background
.bg-green {
  background-color: $mountain-meadow !important;
}

.bg-green-light {
  background-color: $shamrock !important;
}

.bg-yellow {
  background-color: $tulip-tree !important;
}

.bg-brown {
  background-color: $piper !important;
}

.bg-grey-light {
  background-color: $gray69 !important;
}

.object-fit-contain {
  object-fit: contain;
}
