@import "App/Stylesheets/core";

.contact-number-field {
  .ss-code-input {
    width: 3rem !important;
    flex: unset !important;
  }

  .form-control {
    border-radius: 0.5rem !important;
  }

  .ss-select-control {
    flex: 0 0 25% !important;
    border-radius: 0.5rem !important;
  }

  .select-search-control {
    width: 6rem;
  }
}

.timezone-field {
  .select-search-control {
    width: 20rem;
  }

  .select-search-control__menu {
    text-overflow: clip;
    overflow: hidden;
  }
}

.user-profile {
  width: 5rem;
  height: 5rem;

  img {
    width: 100%;
    height: auto;
  }
}

.sports-details-wrap {
  .players-profile-box {
    width: 2rem;
    height: 2rem;

    > img {
      width: 100%;
      object-fit: cover;
      max-height: 2rem;
    }
  }

  .user-text {
    color: $gray44;
  }
}
