@import "App/Stylesheets/core";

.ss-page-aside {
  width: 14rem;
  background-color: $primary;
}

.ss-aside-head {
  padding: 2rem 1rem;
}

.ss-logo-box {
  img {
    width: 10rem;
  }
}

.aside-nav-link {
  &:not(:first-child) {
    margin-top: 1rem;
  }
}
.aside-nav-link {
  &.active,
  &:hover,
  &:active {
    background-color: $steel-blue;

    &:hover {
      background-color: $steel-blue;
    }
  }

  &:hover {
    background-color: rgba($steel-blue, 0.5);
  }

  > i {
    font-size: 1.5rem;
    width: 1.5rem;
    height: auto;
  }

  span {
    color: $gray82;
  }
}

.ss-aside-footer {
  span {
    color: $gray44;
  }

  img {
    width: 6rem;
    height: auto;
  }
}

.ss-profile-details {
  .button-icon {
    padding: 0.75rem;
    &:hover {
      background-color: $merlin;
    }
  }

  svg {
    color: $white;
    width: 1rem;
    height: 1rem;
  }
}
