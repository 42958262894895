/* PlayerActivityCard.css */

.activity-icon {
  font-size: 1.2rem; /* Larger icon size */
}

.activity-label {
  font-size: 1 rem; /* Increased label text size */
  font-weight: 600;
}

.activity-count {
  font-size: 1.25rem; /* Larger badge text size */
  padding: 0.6rem 1rem; /* Adjusted padding for a bigger badge */
}

.activity-item {
  padding: 12px 0; /* Additional padding for separation */
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;

  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  border-radius: 5px;
  &:hover {
    background-color: #f0f0f0;
    color: #007bff;
  }
}

.activity-item:last-child {
  border-bottom: none;
}
