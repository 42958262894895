@import "App/Stylesheets/core";

.rank-preference-wrap {
  .ss-multiselect {
    width: 3rem;
    margin: 0 auto;
  }
  &:not(:first-child) {
    margin-top: 1rem;
  }
}
