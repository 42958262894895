.dashboard .info-card {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: darkblue;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.dashboard .info-card:hover {
  background-color: #f0f0f0;
  color: #007bff;
}
.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .sales-card .card-icon {
  color: blue;
  background: #f6f6fe;
}
.large-card {
  height: 100%;
  min-height: 400px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: darkblue;
  letter-spacing: 1px;
  font-family: "Roboto", "sans-serif";
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}
.card-item {
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  border-radius: 5px;

  &:hover {
    background-color: #f0f0f0;
    color: #007bff;
  }
}
.card-body {
  padding: 0px 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

/* New Styles for Clickable Cards */
.card-container {
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.card-container:hover {
  background-color: #f0f0f0;
  color: #007bff;
}
