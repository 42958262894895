@import "App/Stylesheets/core";

.batch-graph-wrap {
  @include media-breakpoint-up(xl) {
    grid-template-columns: 18.75rem 18.75rem 18.75rem;
  }
}

.survey-status-wrap {
  .ss-card {
    flex: 0 0 12.5rem;
  }
}

.thumbnail-box {
  width: 6rem;
  height: 3.5rem;
  background-color: $gray92;
}

.thumbnail-box-wrapper {
  width: 14.5rem;
  height: 9rem;
  background-color: $gray92;
}

.survey-search {
  .ss-search-control {
    width: 100% !important;
  }
}
