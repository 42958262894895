@import "App/Stylesheets/core";

.ss-toggle-switch {
  display: inline-block;
  height: 18px;
  position: relative;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  width: 11.5rem;
  background-color: $white;
  border: 1px solid $gray44;
  border-radius: 0.5rem;
  height: 2rem;

  input:focus {
    ~ a,
    + label {
      outline: none;
    }
  }

  input {
    opacity: 0;
    z-index: 5;
  }

  > .text-field {
    left: 0;
    width: 100%;

    span {
      top: 0;
      left: 0;
      z-index: 5;
      display: block;
      width: 50%;
      margin-left: 50px;
      width: auto;
      left: 0;
      top: -1px;
      opacity: 1;
      width: 40%;
      line-height: 2.125rem;
    }
  }

  a {
    right: calc(50% - 0.063rem);
    z-index: 4;
    display: block;
    top: 0;
    bottom: -0.063rem;
    padding: 0;
    left: 0;
    width: 50%;
    background-color: $steel-blue;
    border: 1px solid $steel-blue;
    border-radius: 0.5rem;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  > .text-field {
    span {
      &:first-of-type {
        color: $white;
        opacity: 1;
        left: 0;
        margin: 0;
        width: 50%;
      }

      &:last-of-type {
        left: auto;
        right: 0;
        color: $gray44;
        margin: 0;
        width: 50%;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: -2px;
      border-radius: 1.875rem;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
  }

  input:checked ~ {
    a {
      left: 50%;
    }

    .text-field {
      span {
        &:first-of-type {
          left: 0;
          color: $gray44;
        }

        &:last-of-type {
          color: $white;
        }
      }
    }
  }

  .toggle-anchor {
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
  }
}
