.details-precise-box {
  background: #eceff5 0% 0% no-repeat padding-box;
  gap: 10px;
}
.details-precise-inner-box {
  width: 48px;
  height: 48px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.details-precise-label {
  font-size: 22px;
  font-weight: bold;
}

.details-precise-sub-label {
  font-size: 16px;
}
.reports-card-subtitle {
  white-space: nowrap;
  width: 18rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
