@import "App/Stylesheets/core";

.ss-multiselect {
  border-radius: 0.375rem;
  background-color: $white;
  border: 1px solid $border-color;
  cursor: pointer;
  &.active {
    background-color: $steel-blue;
    border-color: $steel-blue;
    color: $white;
  }
}
