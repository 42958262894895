.top-videos {
  .card-body {
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-title {
    font-size: 1rem;
    font-weight: bold;
    min-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .card-img-top {
    height: 200px;
    object-fit: cover;
  }
  .video-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: background-color 0.2s ease, color 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
      color: #007bff;
    }
  }
  .card {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: background-color 0.2s ease, color 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
      color: #007bff;
    }
  }

  .placeholder-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px; /* Matches video card height */
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f9fa;
    text-align: center;
    color: #6c757d; /* Muted text color */
  }

  /* Trophy Icon Styling */
  .activity-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.6rem;
    color: #ffc107; /* Trophy gold color */

    padding: 5px;
    border-radius: 50%;
  }
  .image-container {
    position: relative;
    width: 100%;
    height: 200px;
  }

  .col-lg-3,
  .col-md-6 {
    padding: 0.5rem;
  }
}
