.schedule-details-container {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  width: 100vw; /* 100% of the viewport width */
  height: 100vh; /* 100% of the viewport height */
  padding: 1.5rem; /* Adjusted padding to fit the layout */
  /* bg-light equivalent */
  border-radius: 0.5rem; /* rounded */
  overflow-y: auto;
}

.card {
  border-radius: 10px;
}

.comment-box {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 800px;
}

.comment-box .card-body {
  padding: 5px;
}
