// Custom Scrollbar
@mixin scrollbars() {
  scrollbar-width: thin;
  scrollbar-color: rgba($color: $black, $alpha: 0.1) transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: $black, $alpha: 0.1);
  }

  &::-webkit-scrollbar-track {
    background: rgba($color: $black, $alpha: 0.1);
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: rgba($color: $black, $alpha: 0.1);
    scrollbar-track-color: transparent;
  }
}

// Button Opaque
@mixin button-opaque($color, $opacity) {
  color: $color;
  background-color: rgba($color: $color, $alpha: $opacity);
  border-color: rgba($color: $color, $alpha: 0);
}

// Background Opaque
@mixin bg-opaque($color, $opacity) {
  background-color: rgba($color: $color, $alpha: $opacity) !important;
}
