@import "App/Stylesheets/core";

.media-wrap {
  border-radius: 0.5rem;
  height: 12.5rem;

  img {
    width: 100%;
  }
}

.pdf-box {
  width: 5rem;
  height: 4.5rem;
  border-radius: 0.5rem;
}

.thumbnail-box {
  > img {
    width: 96px;
  }
}

.icon-media {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
