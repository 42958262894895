@import "App/Stylesheets/core";

.ss-upload-image-field {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 0.625rem;

  .browse-image {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }
}
