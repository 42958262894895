// Colors
$gray18: #2e2e2e;
$gray20: #333333;
$gray44: #707070;
$gray69: #afafaf;
$gray73: #bababa;
$gray82: #d2d2d2;
$gray85: #d8d8d8;
$gray87: #dfdfdf;
$gray92: #ebebeb;
$black: #000000;
$indigo: #1f3262;
$piper: #d66426;
$success: #95cd41;
$danger: #e83a14;
$warning: #ffb72b;
$info: #42c2ff;
$merlin: #505e84;
$rock-blue: #8e98ab;
$steel-blue: #4a72b4;
$solitude: #f1f3f6;
$gun-powder: #47485a;
$dark-purple: #77146a;
$linen: #f9f0e4;
$rose-white: #fcf1ed;
$mountain-meadow: #18af46;
$shamrock: #33db65;
$tulip-tree: #e3a634;
$piper: #d66426;

// Theme Colors Overrides
$primary: $indigo;
$secondary: $piper;

// Prefix for :root CSS variables
$variable-prefix: ss-;

// Font
$font-family-sans-serif: "Roboto", system-ui, -apple-system, "Segoe UI",
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-base: var(--#{$variable-prefix}font-sans-serif);

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-mini: $font-size-base * 0.625;
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-md: $font-size-base * 1.125;
$font-size-lg: $font-size-base * 1.25;
$font-size-large: $font-size-base * 3.25;

$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$font-weight-base: $font-weight-normal;

// Body
$body-bg: $gray87;
$body-color: $gray20;
$border-color: $gray85;

// Link
$link-decoration: none;

// Form
$form-label-margin-bottom: 0.25rem;
$form-label-font-size: $font-size-sm;
$form-label-font-weight: $font-weight-normal;
$form-label-color: $body-color;

$input-btn-focus-box-shadow: none;

$input-font-size: $font-size-sm;
$input-bg: transparent;
$input-focus-box-shadow: $input-btn-focus-box-shadow;
$input-placeholder-color: $rock-blue;
$input-border-radius: 0.5rem;

// Border Radius
$border-radius: 0.5rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: $font-size-xs;

// Button
$btn-border-radius-sm: $border-radius;
$btn-font-size: $font-size-sm;

// Shadow
$box-shadow: 0 0.1875rem 0.375rem rgba($black, 0.15);

//Select
$form-select-border-color: $border-color;
$form-select-box-shadow: unset;
$form-select-focus-box-shadow: unset;

$form-text-font-size: $font-size-xs;
$form-feedback-font-size: $form-text-font-size;

// Pagination
$pagination-padding-y: 0.125rem;
$pagination-padding-x: 0.25rem;
$pagination-color: $body-color;

$pagination-active-color: $body-color;
$pagination-active-bg: $gray92;
$pagination-active-border-color: transparent;

$pagination-disabled-color: $body-color;
