html,
body {
  min-height: 100vh;
}

body {
  @extend .scroll-y;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  @extend .scroll-y;
}

// Form
:focus-visible {
  outline: none;
}

.btn {
  &:focus {
    box-shadow: $input-btn-focus-box-shadow;
  }
}

.ss-page-header {
  height: 5rem;

  @include media-breakpoint-down(xl) {
    height: auto;
  }
}

.ss-page-footer {
  height: 4rem;
  background-color: $solitude;
}

.btn-secondary {
  // @include button-variant(background-color, border-color, text-color);
  @include button-variant(
    $secondary,
    $secondary,
    $white,
    darken($secondary, 5%),
    darken($secondary, 7.5%),
    $white,
    darken($secondary, 5%),
    darken($secondary, 7.5%),
    $white
  );
}

.btn-outline-secondary {
  // @include button-variant(background-color, border-color, text-color);
  @include button-outline-variant(
    $secondary,
    $white,
    $secondary,
    $secondary,
    $white
  );
}

// Button
.btn {
  &:focus {
    box-shadow: $btn-focus-box-shadow !important;
  }
}

//Table
.ss-table {
  .players-profile-box {
    width: 2rem;
    height: 2rem;

    > img {
      width: 100%;
      object-fit: cover;
      max-height: 2rem;
    }
  }
  > tbody {
    > tr {
      &.row-clickable {
        &:hover {
          > * {
            background-color: $rose-white;
          }
        }
      }
    }
  }

  thead {
    border-bottom: 2px solid $primary;
  }
  :not(:first-child) {
    border-top: unset;
  }
}

//Pagination

.pagination-wrap {
  .ss-select-control {
    width: 4.5rem !important;
  }

  .page-item {
    padding: 0 0.5rem;

    &.disabled {
      .page-link {
        opacity: 0.5;
      }
    }
  }
  .page-link {
    border: unset;
    border-radius: 0.5rem;
  }
}

// Tabs
.ss-tabs {
  .nav-item {
    padding: 0 1rem;
  }
  .nav-link {
    color: rgba($body-color, 0.6);
    padding: 0.5rem;
    border-color: unset;
    border: unset;

    &.active,
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background-color: transparent;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-bottom: 0.125rem solid $secondary;
      color: $secondary;
    }
  }
}
//Tabs
.ss-tab-content {
  padding: 2rem 1rem;
  @extend .scroll-y;
}
.ss-page-body {
  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    @extend .scroll-y;
  }
}

.table-responsive {
  @include scrollbars();
}
// checkbox/radio
.checkbox-item-box {
  .form-check-input {
    width: 1.125rem;
    height: 1.125rem;
    vertical-align: top;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid $gray44;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }

  .form-check-input:checked {
    border-color: $dark-purple;
    background-color: $dark-purple;
  }

  // Checkbox
  .form-check-input[type="checkbox"] {
    border-radius: 0.25rem;
  }
}
.sports-details-wrap {
  background-color: $gray92;
  border-radius: 0.5rem;

  .select-search-control {
    width: 20rem;
  }
}

.ss-profile-box {
  width: 6.25rem;
  height: 6.25rem;

  > img {
    width: 100%;
    object-fit: cover;
    max-height: 5.625rem;
    border-radius: 0.625rem;
  }
}

.ss-profile-image {
  width: 5rem;
  height: auto;
}

.ss-code-input {
  width: 3.5rem;
}

//Card
.ss-card {
  box-shadow: 0px 3px 6px rgba($black, 0.161);
  border-radius: 0.5rem;
}

//Modal
.ss-modal {
  &.right_modal {
    position: fixed;
    z-index: 99999;
  }
  &.fade {
    .modal-dialog {
      right: -50%;
      -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
      transition: opacity 0.3s linear, right 0.3s ease-out;
    }
  }
  &.show {
    .modal-dialog {
      right: 0;
      -webkit-box-shadow: 0px -0.25rem 0.563rem rgba($black, 0.16);
      box-shadow: 0px -0.25rem 0.563rem rgba($black, 0.16);
    }
  }
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 32rem;
    max-width: 85%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    &.modal-dialog-md {
      @include media-breakpoint-up(lg) {
        width: 60rem;
        max-width: 60rem;
      }
    }
  }
  &.ss-large-modal {
    .modal-dialog {
      width: 48rem;
      max-width: 48rem;
    }
  }
  .modal-content {
    height: 100vh !important;
    border-radius: 0;
    border: none;
  }
}

//List Group

.ss-list-group {
  border-radius: 0;
  .list-group-item {
    cursor: pointer;

    &:active,
    &.active {
      background-color: $steel-blue;
      border-color: $steel-blue;
    }

    + .list-group-item {
      border-top-width: 1px;
    }
  }
}

//Custom Switch
.custom-switch-check {
  .form-check-input {
    &:checked {
      background-color: $success;
      border-color: $success;
      box-shadow: inset 0px 3px 6px rgba($black, 0.161);
    }
  }
  .form-check-label {
    font-size: 0.75rem;
  }
}

//Dropdown
.ss-dropdown {
  .dropdown-toggle {
    &::after {
      content: unset;
    }
  }
}

//Three State Toggle
.ss-three-state-toggle {
  border-radius: 0.5rem;

  .btn-check {
    z-index: 0;
    &:focus {
      + .btn-outline-dark,
      + .btn-outline-secondary {
        box-shadow: none !important;
      }
      .btn-outline-dark,
      .btn-outline-secondary {
        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }
}

//Login Form
.login-form,
.forgot-password-form {
  .form-control {
    color: $white;
  }
}

//Deactivate Modal
.ss-deactivate-modal {
  .deactivate-close-btn {
    .btn-close {
      border: 1px solid $gray18;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 1.5rem;
      opacity: 1;
    }
  }
}

.required-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: $danger;
}

.ss-progress {
  background-color: $gray69;
}

.status-badge {
  background-color: $steel-blue;
  color: $white;
  border-radius: 0.5rem;
  .select-code {
    .select-code__control {
      border-radius: 0.5rem;
    }
  }
}

.select-search-control {
  .select-search-control__control {
    border-radius: 0.5rem;
    min-height: unset;
  }

  .select-search-control__input,
  .select-search-control__placeholder {
    font-size: 0.875rem;
  }

  .select-search-control__input-container {
    margin: 0;
  }

  .select-search-control__indicator {
    padding: 0.25rem 0.5rem;
  }
}
//Progress Bar List
.progress-bar-status-list-wrap {
  .progress-bar-status-list {
    background-color: $gray92;

    .progress-bar-status-item {
      span {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

// Guidelines
.guidelines-modal {
  .modal-dialog {
    max-width: 450px;
    border: 0;
  }
  .modal-content {
    border-radius: 1.5rem;
  }
  .guideline-cancel-button {
    background-color: $gray69;
    color: $white;
    border-color: $gray69;
  }
}

//Page Not Found
.error-template {
  padding: 15rem 15rem;
  text-align: center;
  background-color: white;
}
