.dashboard .top-players {
  font-size: 14px;
  position: relative; /* Allows for positioning the reflective overlay */
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Soft shadow */
  border-radius: 8px;
  overflow: hidden;
}

.dashboard .top-players .table thead {
  background: #f6f6fe;
}

.dashboard .top-players .table thead th {
  border: 0;
}

.dashboard .top-players .dataTable-top {
  padding: 0 0 10px 0;
}
.dashboard .top-players .dataTable-bottom {
  padding: 10px 0 0 0;
}

.player-row {
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: #f0f0f0;
    color: #007bff;
  }
}
