@import "App/Stylesheets/core";

.page-auth {
  background: url("../../../App/images/login-bg@2x.png") no-repeat;
  background-size: cover;
}

.page-auth-section {
  flex: 0 0 50%;
}

.auth-logo {
  width: 15rem;
  > img {
    width: 100%;
  }
}

.card-auth {
  max-width: 27rem;

  .card-title {
    font-size: 1.5rem;
  }
}
