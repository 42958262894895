@import "App/Stylesheets/core";

.batch-select-wrap {
  .ss-select-control {
    width: 100% !important;
  }
}

.batch-time-wrap {
  .ss-select-control {
    border-radius: 0.5rem !important;
  }
}
