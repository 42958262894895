$icomoon-font-family: "sports-icons" !default;
$icomoon-font-path: "../Fonts" !default;
$icon-font-code: "o7y1sp";

$icon-batch: "\e900";
$icon-coach: "\e901";
$icon-dashboard: "\e902";
$icon-drill: "\e903";
$icon-player: "\e904";
$icon-report: "\e905";
$icon-sport: "\e906";
$icon-survey: "\e907";
$icon-academy: "\e907";
$icon-video: "\e908";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icon-font-code}");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icon-font-code}#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$icon-font-code}")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$icon-font-code}")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$icon-font-code}##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-batch {
  &:before {
    content: $icon-batch;
  }
}
.icon-coach {
  &:before {
    content: $icon-coach;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-drill {
  &:before {
    content: $icon-drill;
  }
}
.icon-player {
  &:before {
    content: $icon-player;
  }
}
.icon-report {
  &:before {
    content: $icon-report;
  }
}
.icon-video {
  &:before {
    content: $icon-report;
  }
}

.icon-sport {
  &:before {
    content: $icon-sport;
  }
}
.icon-survey {
  &:before {
    content: $icon-survey;
  }
}

.icon-academy {
  &:before {
    content: $icon-academy;
  }
}
.icon-video {
  &:before {
    content: $icon-video;
  }
}
