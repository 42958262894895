.academy-list-address {
}

// .admin-name {
//   text-align: center;
// }

// .academy-table-headers {
//   text-align: center;
// }

// .align-middle {
//   text-align: center;
// }

.academy-actions {
  display: flex;
  // justify-content: center;
}

.add-admin-btn {
  margin: 10px;
}
