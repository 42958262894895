@import "App/Stylesheets/core";

.checkbox-item-box {
  border-radius: 0.75rem;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  width: 6.125rem;
  height: 6.125rem;

  .img-box-wrap {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem auto 0;
    > img {
      width: 100%;
      height: 2rem;
      object-fit: cover;
    }
  }

  .checkbox-field {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
}
